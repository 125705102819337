.contacts-wrap-inner {
  display: flex;
  max-width: 1500px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.contacts {
  display: flex;
  &__text-wrap {
    width: 40%;
    padding-bottom: 3rem;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__img-wrap {
    position: relative;
    height: 100%;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }

    &__btn {
      z-index: 1;
      color: white;
      position: absolute;
      font-size: 1rem;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        background-color: black;
        padding: 0.6rem;
        box-shadow: black 2px 1px 3px;
        display: flex;
        transition: 0.3s ease-in-out;
      }
    }
  }

  &__map-wrap {
    width: 60%;
    margin-top: 3.45rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    min-height: 200px;
    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0;
    }

    iframe {
      width: 100%;
      max-height: 372px;
      min-height: 200px;
      height: 100%;
    }

    img {
      width: 100%;
      max-height: 372px;
      min-height: 200px;
      height: 100%;
      object-fit: cover;
      @media (max-width: 767px) {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}