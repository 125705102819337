.header {
  background-color: black;
  font-family: "Lora", serif;

  .dropdown-menu {
    padding: 0.3rem 0.5rem;
  }

  .dropdown-menu {
    border: none;
  }

  a,
  a:hover {
    color: black;
  }

  .btn-header,
  btn-header:focus,
  btn-header:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    box-shadow: none;
  }

  .btn:hover {
    color: white;
  }

  .btn-check:focus + .btn,
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    max-width: 1500px;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-list' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E");
  }

  .navbar-light .navbar-nav .nav-link {
    color: white;
  }

  .dropdown-menu {
    border-radius: 0;
  }

  .header__middle {
    display: flex;
    justify-content: center;
    @media (max-width: 1199px) {
      justify-content: flex-start;
    }
  }

  &__logo {
    max-width: 200px;
    max-height: 82px;
    min-width: 184px;
    min-height: 75px;
  }

  &__signin, &__signup {
    color: white !important;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .dropdown-menu {
      background-color: #fff0;
    }
    .dropdown-item {
      color: white;
    }
    .header__logo {
      display: none;
    }
    .navbar-collapse {
      background-color: #00000094;
      border-radius: 4px;
    }
    .header-wrapper {
      align-items: flex-start;
    }
  }

  .header__logo-mobile {
    display: none;
    @media (max-width: 767px) {
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    img {
      width: 11.333rem;
    }
  }

  @media (max-width: 1199px) {
    .header {
      border-bottom: 0;

      &__logo {
        display: none;
      }

      &__account__icon {
        font-size: 1.7rem;
      }
    }
  }
}
