.exercise-new {
  &__lesson {
    border: 2px solid #0f6ffd;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    background-color: #0f6ffd14;
  }
  &__question {
    border: 2px solid #3ffd0f;
    background-color: #97fd0f1f;
  }

  &__lesson-buttons {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    background: #f1f1f1;
  }
  .dropdown-menu {
    background-color: #f2f2f2;
    color: white !important;
  }
}