.exercise-item {
  &__video-description-wrap {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__iframe-wrap {
    width: 50%;
    padding-right: 1rem;
    @media (max-width: 767px) {
      width: 100%;
      padding-right: 0;
    }
  }

  &__text-wrap {
    width: 50%;
    padding-left: 1rem;
    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0;
      padding-top: 1rem;
    }
  }
  .form-check-input {

  }
}