.questions-sl-wrap-outer {
}

.questions-sl-wrap-inner {
  max-width: 1500px;
}

.questions-sl-first-block {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1rem;
  @media (max-width: 767px) {
    max-width: 100%;
    padding-right: 0;
  }
}

.questions-sl-second-block {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  @media (max-width: 767px) {
    max-width: 100%;
    padding-left: 0;
  }
}

.questions-sl-blocks-wrap {
  display: flex;
  padding-bottom: 3rem;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.questions-sl__item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    padding-bottom: 1rem;
  }

  &__numb {
    background-color: #FBC9C1;
    font-weight: 600;
    font-size: 2rem;
    border-radius: 50%;
    height: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    @media (max-width: 576px) {
      height: 50px;
      min-width: 50px;
      font-size: 1.778rem;
    }
  }

  &__text {
    &__top {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 1.333rem;
      color: #121212;
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      font-size: 1.111rem;
      color: #131313;
    }
  }
}