.exercise-item-textarea {
  background: linear-gradient(181deg, #d2d3d9, transparent);
  border-radius: 5px 5px 0 0;

  &__title {
    color: black;
    text-transform: uppercase;
    font-size: 0.667rem;
    padding: .4rem 1rem;
    font-weight: bold;
  }

  textarea {
    width: 100%;
    height: 208px;
    margin: 0px;
    border: 1px solid #d6d7dd;
    padding: 0.8rem;
    outline: none;
    @media (max-width: 767px) {
      height: 114.3px;
    }
  }
}