.auth-modal {
  &__header {
    display: flex;
    justify-content: flex-start;
    font-family: "Lora";
    font-size: 1.5rem;
    padding: 0;
    margin: 0rem 1rem;
    border-bottom: 1px solid #dee2e6;
  }

  &__header > span {
    margin-right: 1.3rem;
    padding: 1rem 0;
    cursor: pointer;
  }

  input {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    border: 2px solid #ececec;
    height: 2.2rem;
    box-shadow: none;
  }

  input:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    border: 2px solid #cfcfcf;
  }

  .form-label {
    margin-bottom: 0rem;
  }
}

.selected-modal-header {
  border-bottom: 3px solid black;
}

.social-network__instagram::after {
  font-family: "FontAwesome";
  content: "\f16d";
  font-size: 1.1rem;
}

.social-network__vk::after {
  font-family: "FontAwesome";
  content: "\f189";
  font-size: 1rem;
}

.social-network__facebook::after {
  font-family: "FontAwesome";
  content: "\f09a";
  font-size: 1.3;
}

.social-network__ok::after {
  font-family: "FontAwesome";
  content: "\f263";
  font-size: 0.9rem;
}

.social-network-icons {
  border: 1px solid;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  margin: 0.3rem;
}


.social-network-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.2rem;
}

.social-network-icons-wrapper {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  a:hover {
    text-decoration: none;
  }
}
