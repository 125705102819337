.btn {
    &__arrow::before {
        font-family: "FontAwesome";
        content: "\f105";
        font-size: 30px;
        line-height: 15px;
    }
    &__btn {
        padding: 5px 15px 5px 15px;
    }
}
