.videocource-wrap {
  padding-bottom: 2rem;

  img {
    object-fit: cover;
    width: 100%;
  }

  @media (max-width: 767px) {
    padding-bottom: 1rem;
  }
}

.videocource {
  &__img-wrap {
    display: flex;
    position: relative;
  }

  &__rating {
    position: absolute;
    background: black;
    color: white;
    top: .9rem;
    left: 1.9rem;
    width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-weight: 500;
      font-size: 1.222rem;
      padding-bottom: 0.8rem;

      @media (max-width: 767px) {
        padding-top: 1rem;
      }
    }

    &__icon {
      margin-right: 0.8rem;
      font-size: 1.2rem;
    }

    &__price {
      font-family: Lora, serif;
      font-size: 1.222rem;
      font-weight: 500;
      margin-bottom: .5rem;
    }
  }

  &__btn-wrap {
    display: flex;
    align-items: stretch;
    margin-top: 1rem;

    a,
    a:hover {
      color: white;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    &__btn-wrap {
      display: flex;
      justify-content: center;
    }
  }

  &__buy-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 15.3px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
    border: none;

    font-family: 'Montserrat';
    font-size: 18px;
    line-height: 22px;

    &-asterisk {
      display: inline;
      font-size: 12px;
      padding-right: 5px;
    }
  }

  &__buy-btn:hover {
    box-shadow: 2px 1px 5px black;
  }

  &__gift-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 19.3px;
    height: 3.3rem;
    background-color: white;
    color: black;
    font-weight: 600;
    cursor: pointer;
    border: none;
    border: 1px solid gray;
    margin-left: 15px;
  }

  &__description-wrap {
    padding-top: 2rem;

    @media (max-width: 767px) {
      padding-top: 1.5rem;
    }
  }

  &__price-free {
    background: #A40000;
    font-weight: 600;
    color: white;
    padding: 0.2rem 1rem;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  &__action-price {
    font-family: "Lora", serif;
  }

  &__future-price {
    font-family: "Lora", serif;
  }

  &__after-btns {
    padding-top: 8px;
    font-size: 14px;
  }
}