.competences-wrap {
  flex-direction: column;
  max-width: 1500px;
  padding-bottom: 3rem;
}

.competences {
  display: flex;
  flex-direction: column;

  &__items-wrap {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
}