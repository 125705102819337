.data-succes-modal {
    .modal-dialog {
        display: flex;
        justify-content: center;
    }
    .modal-content {
        width: 20rem;
        align-items: center;
    }
    .modal-body {
        display: flex;
        align-items: center;
    }
    svg {
        font-size: 1.9rem;
    }
}
