.kits-more-details__wrap {
  padding-bottom: 3rem;

  p {
    text-indent: 0;
  }

  h4 {
    font-family: 'Lora';
  }

  h4>span {
    font-family: 'Lora' !important;
  }
}

.kit-more-details {
  &__info {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    line-height: 1.6rem;
    border-top: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    align-items: center;

    s {
      color: #adadad;
    }
  }

  &__actual-price {
    font-weight: 600;
  }

  &__btn-wrap {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  &__after-btns {
    padding-top: 8px;
    font-size: 14px;
  }

  &__buy-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 15.3px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
    border: none;

    &-asterisk {
      display: inline;
      font-size: 12px;
      padding-right: 5px;
    }
  }

  &__buy-btn:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 15.3px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
    border: none;
    box-shadow: 2px 1px 5px black;
    text-decoration: none;
  }
}

.kits-more-details {
  &__title {
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
  }

  &__inner-list-wrap {
    display: flex;
    justify-content: center;
  }

  &__description {
    padding-left: 1.6rem;
  }

  &__list-wrap {
    a:hover {
      text-decoration: none;
    }

    @media (max-width: 767px) {
      width: 80%;
      min-width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 575px) {
      width: 100%;
      min-width: 100%;
    }
  }

  &__list-wrap>h4 {
    margin-bottom: 1rem;
  }

  &__list-title {
    text-decoration: underline;
    text-align: center;
    margin-bottom: 1.3rem;
  }
}

.buy-form__btn-wrap {
  margin-top: 1.5rem;
}