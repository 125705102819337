.exercise-item-question-with-buttons__wrap {
  button {
    border: 2px solid;
    border-radius: 5px;
    background-color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    vertical-align: middle;
    width: 150px;
    height: 45px;
    font-weight: 600;
  }

  .exercise-item-question-with-buttons {
    display: flex;

    &__yes {
      margin-right: 1rem;
      border-color: #4fb560;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 1.6rem;

      svg {
        font-size: 2.2rem;
      }

      &:hover {
        color: #4fb560;
      }
    }

    &__no {
      border: 2px solid #ff0000;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 1.6rem;

      svg {
        font-size: 2.2rem;
      }

      &:hover {
        color: #ff0000;
      }
    }
  }
}