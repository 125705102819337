.home-page-qa-wrap-outer {
  background: #E5E5E5;
  padding-bottom: 3.2rem;
}

.home-page-qa-wrap-inner {
  max-width: 1500px;
}

.home-page-qa {

  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  &__iframe-wrap {
    width: 100%;
    height: 36rem;
    @media (max-width: 767px) {
      height: 31rem;
    }
    @media (max-width: 575px) {
      height: 20rem;
    }
  }

  &__text {
    padding-top: 1.5rem;
  }

  &__tags {
    padding-top: 1.5rem;
    font-family: 'Lora';
    font-size: 1.2rem;
    cursor: pointer;
  }

  &__iframe {
    height: 100%;
    width: 100%;
  }

  &__img-wrap {
    height: 100%;
    width: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      z-index: 1;
    }

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &__circle {
      z-index: 1;
      color: white;
      position: absolute;
      font-size: 4rem;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      @media (max-width: 767px) {
        font-size: 4.3rem;
      }
    }
  }
}
