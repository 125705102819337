.answers-wrap {
  padding-top: 1.8rem;
  &__form {
    .form-label {
      font-weight: 500;
    }
  }
  &__form > div {
    padding-bottom: .8rem;
  }
}