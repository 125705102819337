.footer {
  background-color: black;
  justify-content: center;
  color: white;
  font-family: Lora;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: white;
  }

  a:hover {
    color: white;
  }

  .nav-link:hover,
  .nav-link:focus {
    color: white;
    text-decoration: underline;
  }

  &__wrap {
    font-weight: 500;
    font-family: Montserrat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    img {
      width: 100%;
      max-width: 184px;
      height: 100%;
      max-height: 75px;
      object-fit: contain;
    }
  }

  &__revievs {
    @media (max-width: 1199px) {
      display: none;
    }
  }

  &__contacts-small {
    @media (max-width: 1199px) {
      display: none;
    }
  }

  &__made-in {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: monospace;
    color: #7d7d7d;

    a {
      color: #7d7d7d;
    }
  }
}
