.pt-status-wrap {
  display: flex;
  margin-top: 2rem;
  @media (max-width: 767px) {
    flex-direction: column;
  }

  h4 {
    margin-bottom: 0;
    width: 95%;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
  }

  h4:after {
    content: '';
    background-color: #ece6e6;
    flex-grow: 1;
    height: 1px;
    margin: 0 0 0 11px;
    display: flex;
    align-items: center;
  }
}

.pt-status {
  &__icon {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }

  &__right-block, &__left-block {
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__right-block {
    @media (max-width: 767px) {
      margin-top: 2rem;
    }
  }

  &__not-fulfilled-btn-wrap {
    display: flex;
    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  &__not-fulfilled-btn {
    background-color: #ed1d24;
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    color: white;
    font-weight: 500;
  }

  &__right-block__btn {
    color: black;
    background-color: #ffffff;
    border: 2px solid #ed1d24;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;

    svg {
      font-size: 1.3rem;
      margin-right: 0.5rem;
      color: #ed1d24;
    }
  }

  &__question-btn-wrap {
    .btn-primary:hover {
      color: black;
      background-color: #ed1d24;
      border-color: #ed1d24;

      svg {
        color: black;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }

  &__question-btn {
    color: black;
    background-color: white;
    border: 2px solid #ed1d24;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;

    svg {
      color: black;
      margin-right: 0.4rem;
      font-size: 1.3rem;
    }
  }

  &__check-button-wrap {
    .btn-primary:hover {
      color: black;
      background-color: white;
      border-color: #ed1d24;
      box-shadow: 0px 4px 32px rgb(223 7 9 / 14%), 0px 16px 24px rgb(223 7 9 / 10%), 0px 2px 6px rgb(223 7 9 / 8%), 0px 0px 1px rgb(0 0 0 / 4%);

      svg {
        color: black;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }

  &__check-button {
    color: white;
    background-color: #ed1d24;;
    border: 2px solid #ed1d24;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;
    padding: 0.5rem 2.3rem;
    box-shadow: 0px 6px 32px rgb(39 39 39 / 14%), 0px 16px 24px rgb(70 70 70 / 10%), 0px 2px 6px rgb(223 7 9 / 8%), 0px 0px 1px rgb(0 0 0 / 4%);

    svg {
      color: white;
      margin-right: 1rem;
    }
  }
}