.videocources {
  &__sort {
    cursor: pointer;
    font-family: Lora;
    font-size: 1.1rem;

    span {
      padding: 1rem;
      background-color: black;
      color: white;
      padding: 0.3rem 0.7rem;
    }

    &__first {
      margin-right: .8rem;
    }
  }

  &__items-wrap {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding-bottom: 1.5rem;
    justify-content: center;
    min-height: 22vh;
    @media (max-width: 1199px) {
      grid-template-columns: 33% 33% 33%;
    }
    @media (max-width: 767px) {
      grid-template-columns: 49% 49%;
    }
    @media (max-width: 575px) {
      grid-template-columns: 98%;
      justify-items: center;
    }
  }

  &__tags {
    display: flex;
    justify-content: flex-end;
    font-family: Lora;
    font-size: 1.1rem;
    cursor: pointer;
    padding-bottom: 2rem;
    cursor: pointer;
    @media (max-width: 1200px) {
      display: none;
    }

    span {
      margin-left: 1rem;
    }
  }

  //.kit-title-cutter {
  //    font-size: 1.1rem;
  //    overflow: hidden;
  //    text-overflow: ellipsis;
  //    display: -webkit-box;
  //    -webkit-box-orient: vertical;
  //    -webkit-line-clamp: 1;
  //    line-clamp: 1;
  //}

  &__dropdown {
    display: none;
    cursor: pointer;
    padding-bottom: 2rem;
    justify-content: flex-end;
    @media (max-width: 1200px) {
      display: flex;
    }
    @media (max-width: 575px) {
      justify-content: center;
    }
  }
}