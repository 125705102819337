.cources-slider {
    .videocources-slider-btn {
        padding-top: 6rem;
        text-align: center;
    }
    .slick-list {
        margin-left: 0;
    }
    .slick-track {
        display: flex;
    }
    .slick-slide slick-active:first-child {
        background-color: red;
    }
    .slick-list {
        margin-left: 0;
    }
    .slick-track {
        display: flex;

        .slick-slide {
            height: auto;
            display: flex;
            justify-content: center;
            > div {
                display: flex;
                max-width: 96%;
            }
        }
    }
    @media (max-width: 767px) {
        .slick-dots {
            bottom: -3.3875rem;
        }
        .videocources-slider-btn {
            padding-top: 2rem;
            text-align: center;
        }
    }
    .slick-slider .slick-slide > div {
        margin: 0;
    }
}
