.pass-recovery-page-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-label {
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        font-size: 1.1rem;
    }
}
