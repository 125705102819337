.about {
    padding-bottom: 3rem;
    p {
        margin-bottom: 0;
    }
    .about__p-wrapper {
        > p {
            text-indent: 1.5em;
        }
    }
    .breadcrumb {
        margin-bottom: 0rem;
    }
    &__teachers {
        background: #f2f2f2;
        padding: 1rem 0.8rem;
        b {
            font-size: 1.2rem;
        }
    }
    &__teachers > div {
        margin-bottom: 0.5rem;
    }
    &__teachers > div::before {
        font-family: "FontAwesome";
        content: "\f111";
        padding-right: 0.5rem;
    }
    &__img {
        @media (max-width: 992px) {
            display: none;
        }
    }
    &__text-wrap-left {
        @media (min-width: 992px) {
            padding-right: 4rem;
        }
    }
    &__text-wrap-right {
        @media (min-width: 992px) {
            padding-left: 4rem;
        }
    }
    &__img-mobile {
        @media (min-width: 576px) {
            display: none;
        }
    }
    &__img-phablet {
        @media (max-width: 576px) {
            display: none;
        }
    }
    &__img-phablet {
        @media (min-width: 992px) {
            display: none;
        }
    }
    .standart-title {
        @media (min-width: 768px) {
            padding-top: 3.45rem;
        }
    }
}
