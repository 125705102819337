.pass-input {
  &__with-eye-wrap {
    position: relative;
    .form-control.is-invalid, .was-validated .form-control:invalid {
      background-position: right calc(2.375em + .1875rem) center;
    }
  }

  &__with-eye__eye {
    position: absolute;
    right: 13px;
    top: 9px;
  }
}