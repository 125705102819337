.kits-wrap-inner {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding-bottom: 1.5rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: space-between;

    @media (max-width: 767px) {
        grid-template-columns: 49% 49%;
    }
    @media (max-width: 575px) {
        grid-template-columns: 98%;
        justify-items: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
