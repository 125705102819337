.learn-more-link-wrap {
  background-color: black;
  padding: 0.6rem;
  box-shadow: black 2px 1px 3px;
  display: flex;
  transition: 0.3s ease-in-out;

  &:hover {
    transition: 0.3s ease-in-out;
    box-shadow: black 3px 2px 6px;
  }
}

.learn-more-link {
  display: inline-block;
  color: #ffffff;
  padding: 0.833rem;
  position: relative;
  letter-spacing: 0.056rem;

  &__white-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: #ffffff;
    display: flex;
    transition: 0.3s ease-in-out;

    svg {
      width: 1.333rem;
      height: 1.333rem;
      margin: auto;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 2;
    padding: 1.333rem 0.444rem;
    padding-left: 1rem;
    transition: 0.3s linear;
    font-weight: 400;
  }

  &:hover {
    color: white;

    .learn-more-link__white-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .learn-more-link__text {
      transform: translate(20px, -50%);
      font-weight: 500;
    }
  }
}

