body {
  font-family: "Montserrat" !important;
}

body > div > a {
  text-decoration: none;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-family: Lora;
  font-size: 2rem;
  line-height: 2.556rem;
}

.slick-dots li button:before {
  font-family: "FontAwesome";
  content: "\f111";
}

.slick-active button:before {
  font-size: 1.2rem !important;
}

.slick-slider {
  .slick-next::before,
  .slick-prev::before {
    font-family: "FontAwesome";
    content: "\f104";
    color: black;
    font-size: 30px;
    line-height: 15px;
  }

  .slick-next::before {
    content: "\f105";
  }

  .slick-slide > div {
    margin: 0 10px;
  }

  .slick-list {
    margin: 0 -10px;
    height: auto;
  }

  .slick-dots {
    bottom: -3.5rem;
  }
}

.videocources {
  &__title {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 1;
    font-weight: 600;
    margin-top: 0.3rem;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .title-responsive {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .slider-wrap {
    overflow: hidden;
  }
  .breadcrumb {
    margin-bottom: -0.5rem;
  }
  .dropdown-menu-wrapper {
    background-color: black;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 15px;
  }
  .slick-active button {
    border: none !important;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 18px;
  }
}

@media (max-width: 1550px) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}

.row-component-wrapper {
  //Все компоненты начинаются с Row, cтраницы с Col!
  justify-content: center;
}

.col-page-wrapper {
  //Все компоненты начинаются с Row, cтраницы с Col!
  max-width: 1500px;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;

  &__stick {
    width: 3.222rem;
    height: 2px;
    background-color: black;
    display: inline-block;
    margin-right: 0.556rem;
    min-width: 46px;
  }

  &__text {
    font-size: 1rem;
    margin: 0;
  }
}

.close ::before {
  font-family: "FontAwesome";
  content: "\f00d";
  visibility: visible;
  color: black;
  font-weight: bold;
  height: auto;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  visibility: hidden;
  position: absolute;
  right: 1.778rem;
  top: 1.333rem;
  width: 1.111rem;
  height: 1.111rem;
  background-color: rgb(247, 12, 12, 0.5);
  font-size: 1rem;
  display: flex;
}

.app-wrapper {
  overflow: hidden;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  border: none;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
  border: 2px solid #ececec;
}

.dropdown-item.active,
.dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: #f7f7f7;
}

.app-layout-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
  text-decoration: underline;
}

a:focus {
  outline: none;
}

p {
  text-indent: 3rem;
}

.rdw-editor-main {
  border: 2px solid #ececec;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  box-shadow: none;
}

.modal-header .btn-close {
  font-size: 1rem;
}

//----------------------Cinsultation landing------------------------//

.cources-landing-container-wrap {
  justify-content: center;
  display: flex;
}

.container-fluid {
  overflow: hidden;
}

.title {
  font-weight: 600;
  font-size: 1.444rem;
  color: #445285;
  @media (max-width: 768px) {
    font-size: 2.3rem;
  }
}

.modal-backdrop.show {
  opacity: 1;
  background: linear-gradient(#000000, #0000009e);
}

//----------------------School landing------------------------//

.school-landing-container-wrap {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.sl-title {
  font-weight: 600;
  font-size: 2.222rem;
  text-align: center;
  color: #121212;
  padding-top: 2.444rem;
  padding-bottom: 0.9rem;
  @media (max-width: 767px) {
    font-size: 1.8rem;
    padding-top: 2.444rem;
    padding-bottom: 1.5rem;
  }
}

.message {
  padding: 15px;

  textarea {
    width: 100%;
    height: auto;
    border: none;
    resize: none;
  }

  &.checking {
    background-color: #fff2d6;

    textarea {
      background-color: #fff2d6;
      color: #212529;
    }
  }

  &.finished {
    color: #FFFFFF;
    background-color: #4bb34b;
  }

  &.failed {
    color: #FFFFFF;
    background-color: #ff3347;
  }
}

.account {
  a {
    color: black;
    text-decoration: none;
  }

  &__navbar {
    margin-top: 1.8rem;
    display: flex;
    flex-direction: column;
    font-family: Lora;
    text-decoration: none;
    padding: 0 .8rem;
  }

  &__exit-btn {
    cursor: pointer;
  }

  &__user {
    text-align: center;
    margin-bottom: 20px;

    &-avatar {
      background-color: #D8D8D8;
      font-weight: 500;
      padding: 36px 45px;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.03em;
      color: #747474;
      border-radius: 50%;
      width: 116px;
      height: 116px;
      margin: 0 auto;
    }

    &-username {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &-email {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.03em;
      color: #626262;
    }
  }

  &__links {
    @media (max-width: 767px) {
      display: none;
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      transition: transform 250ms;
      padding: 5px;
      border-left: 3px solid transparent;
    }

    a:hover {
      background: #e9ecef;
      text-decoration: none;
    }
  }


  .selected {
    width: 100%;
    border-left: 3px solid #000000;
    background: #e9ecef;
  }

  &__content {
    @media (min-width: 767px) {
      border-left: 2px solid #ececec;
      min-height: 80vh;
    }
  }
}
