.pt__download-btn-wrap {
  @media (max-width: 767px) {
    justify-content: center;
    display: flex;
  }
}

.pt-status__question-btn-wrap {
  @media (max-width: 767px) {
    justify-content: center;
    display: flex;
  }
}

.pt {
  &__download-btn-wrap {
    .btn-primary:hover {
      color: black;
      background-color: #ed1d24;
      border-color: #ed1d24;

      svg {
        color: black;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }

  &__download-btn {
    color: black;
    background-color: #ffffff;
    border: 2px solid #ed1d24;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;

    svg {
      font-size: 1.3rem;
      margin-right: 0.5rem;
      color: #ed1d24;
    }
  }
}

.pt-wrap {
  iframe {
    @media (max-width: 767px) {
      height: 250px;
    }
  }

  h6 {
    font-weight: 500;
    margin-bottom: 1.2rem;
  }

  h5 {
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 1.1rem;
  }

  h4 {
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .tr-top {
    background-color: #d2d3d9;
  }

  .pt-table-wrap {
    background: linear-gradient(181deg, #d2d3d9, transparent);
    border-radius: 5px 5px 0 0;

    textarea {
      width: 100%;
      height: 208px;
      margin: 0px;
      border: 1px solid #d6d7dd;
      padding: 0.8rem;
      outline: none;
      @media (max-width: 767px) {
        height: 114.3px;
      }
    }

    .pt-textarea-wrap {
      background-color: red;

      textarea {
        width: 100%;
        height: 150px;
      }
    }
  }

  .pt-table-title {
    color: black;
    text-transform: uppercase;
    font-size: 0.667rem;
    padding: .4rem 1rem;
    font-weight: bold;
  }

  .pt-adding-button {
    color: black;
    background-color: #ffffff;
    border: 2px solid #ed1d24;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;

    svg {
      font-size: 1.3rem;
      margin-right: 0.5rem;
      color: #ed1d24;
    }
  }

  .pt-done-btn-wrap {
    max-width: 470px;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    align-items: center;
    padding-top: 1rem;
    text-align: center;
  }
}

.pt0 {
  &__top {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    &__iframe-wrap {
      width: 50%;
      padding-right: 1rem;
      @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  &__text-wrap {
    width: 50%;
    padding-left: 1rem;
    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0;
      padding-top: 1rem;
    }
  }
}

//////////////////////NEW STYLES

.pt0 {
  &__text-edit-btn {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
}