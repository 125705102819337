.consultation__title {
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .consultation__title {
        display: block;
        text-align: center;
    }
    .consultation__line {
        display: none;
    }
    .background-wrapper {
        width: 100%;
        background-color: black;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 39% 24%;
        min-height: 18.125rem;
        height: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 2.9rem;
    }
}

.background-wrapper {
    width: 100%;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 18.125rem;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 2.9rem;
}
