.payment-epoint-button-wrapper {
  margin-left: 10px;

  .payment-epoint-button {
    &__buy-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.7rem 15.3px;
      background-color: white;
      color: black;
      font-weight: 600;
      height: 100%;
      cursor: pointer;
      border: 1px solid #000000;

      font-family: 'Montserrat';
      font-size: 18px;
      line-height: 22px;
    }

    &__buy-btn:hover {
      box-shadow: 2px 1px 5px black;
    }
  }
}