button:disabled,
button[disabled] {
    border: 1px solid red;
    background-color: #cccccc;
    color: #666666;
}
.regular-btn {
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none;
    box-shadow: none;
}
