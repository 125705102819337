.exercise-item-table {
  &__th {
    background-color: #d2d3d9 !important;
  }

  input {
    width: 100%;
    border: none;
  }

  &__table {
    background: red;
    padding: 0 !important;
    border: none;
    .form-control {
      border: none;
    }
  }
}

