.pt-add-field-btn-wrap {
  .btn-primary:hover {
    color: black;
    background-color: #ed1d24;
    border-color: #ed1d24;

    svg {
      color: black;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}


.pt-add-field-btn {
  display: inline;
  color: black;
  background-color: #ffffff;
  border: 2px solid #ed1d24;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 600;


  svg {
    font-size: 1.3rem;
    margin-right: 0.5rem;
    color: #ed1d24;
  }
}