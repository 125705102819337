.contact {
    max-width: 1500px;
    padding-bottom: 1.8125rem;
    input {
        margin-bottom: 1rem;
        width: 100%;
        height: 2.75rem;
        border: 2px solid #ececec;
        padding-left: 15px;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
    }
    textarea {
        width: 100%;
        resize: none;
        border: 2px solid #ececec;
        padding: 0.875rem;
        margin-bottom: 1rem;
        box-shadow: none;
    }
    select {
        width: 100%;
        margin-bottom: 1rem;
        height: 2.75rem;
        border: none;
        padding-left: 1rem;
        box-shadow: none;
        color: #6c757d;
        border: 2px solid #ececec;
    }
    .form-check-label {
        padding-left: 0.5rem;
    }
    .form-check-input {
        width: 20px;
        height: 20px;
        border: 2px solid #ececec;
        margin-top: 0;
        @media (min-width: 769px) {
            width: 1.2rem;
            height: 1.2rem;
        }
    }
    .invalid-tooltip {
        top: 0.379rem;
        right: 1.644rem;
        background-color: rgba(255, 255, 255, 0);
        color: #dc3545;
    }
    .form-control {
        color: #212529;
        background-color: #fff;
        outline: 0;
        border: 2px solid #ececec;
    }
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
        border: 2px solid #ececec;
    }
    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus {
        border-color: #ececec;
        box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 0%);
    }
    .contact__cheeck {
        label {
            color: black;
        }
    }
    .invalid-feedback {
        padding-left: 0.5rem;
    }
}

@media (max-width: 768px) {
    .contact__inputs {
        display: flex;
        flex-direction: column;
    }
}
