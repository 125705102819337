.aq-wrap {
  padding-bottom: 3rem;
}

.aq {
  padding-bottom: 1.5rem;
  &__search {
    position: relative;

    &__icon {
      position: absolute;
      top: 21px;
      right: 35px;
      fontSize: 1.1rem;
    }

    &__input {
      width: 100%;
      height: 60px;
      background: #FCFCFC;
      border: 2px solid #ECECEC;
    }
  }

  &__settings {
    margin-top: 3rem;
    display: flex;
    margin-bottom: 2.5rem;
    justify-content: flex-end;
    @media (max-width: 968px) {

    }
  }

  &__sort {
    cursor: pointer;
    font-family: Lora;
    font-size: 1.1rem;

    span {
      padding: 1rem;
      background-color: black;
      color: white;
      padding: 0.3rem 0.7rem;
      margin-right: 1rem;
    }

    &__first {
      margin-right: .8rem;
    }
  }

  &__tags {
    display: flex;
    justify-content: flex-end;
    font-family: Lora;
    font-size: 1.1rem;
    cursor: pointer;

    span {
      margin-left: 1rem;
    }
  }

  &__grid-wrap {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    justify-content: space-between;

    a:hover {
      text-decoration: none;
    }

    @media (max-width: 767px) {
      grid-template-columns: 99%;
    }
  }
}

