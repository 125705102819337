.competences-item-wrap {
  width: 33%;
  height: 26.611rem;
  background-size: cover !important;
  @media (max-width: 767px) {
    width: 75%;
    margin-bottom: 2rem;
  }
  @media (max-width: 575px){
    width: 95%;
  }
}