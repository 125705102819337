.gift-button-wrapper {
  padding: 16px 0;

  .gift-button {
    &__container {
      background: no-repeat center/cover url('./images/background.png');
      padding: 10px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }

    &__text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 100%;
      color: #fff;
    }

    &__buy-btn {
      padding: 10px;
      font-family: 'Lora';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      background-color: #fff;
    }
  }

  .gift-button__container-simple {
    .gift-button__buy-btn {
      font-family: 'Montserrat';
      font-size: 1rem;
      font-weight: 400;
      padding: 0.9rem 2rem;
      background-color: black;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-shadow: black 2px 1px 3px;

      @media (max-width: 767px) {
        padding: 0.7rem 1.2rem;
      }
    }
  }
}

.simple {
  padding: 0.4rem 0 0 0;
}