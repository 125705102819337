.countdown {
  background-color: #f7f7f7;
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &__price {
    display: flex;
    justify-content: center;
    font-family: Lora, serif;
    font-size: 1.6rem;
  }

  &__alert {
    display: flex;
    justify-content: center;
  }

  &__items {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
  }

  &__future-price {
    display: flex;
    justify-content: space-around;
    font-family: Lora, serif;
    font-size: 1.6rem;
  }

  &__item {
    background-color: #6a8807;
    padding: 1rem;
    margin: 0.3rem;

    div {
      font-size: 2.222rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      color: white;
    }
    span {
      color: white;
    }
  }
}