.videocources-slider-item-wrap {
  display: flex;
  flex-direction: column;
  border: 2px solid #F5F5F5;
  justify-content: space-between;
  position: relative;

  img {
    width: 100%;
    min-height: 129px;
    object-fit: cover;
    height: 100%;
  }
}

.videocources-slider-item {
  display: flex;
  flex-direction: column;
  padding: 0.8125rem;
  justify-content: space-between;
  height: 50%;

  &__img-wrap {
    width: 100%;
    min-height: 129px;
    object-fit: cover;
    height: 50%;
    position: relative;
  }

  &__button-wrap {
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(360deg, black, transparent);
    cursor: pointer;
    padding: 0.5rem;

    a {
      color: white;
    }

    a:hover {
      color: white;
      text-decoration: none;
    }
  }

  &__tags {
    font-size: 0.8rem;
    color: #7D7D7D;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  &__info {
    fontSize: .875rem;
    color: #7D7D7D;

    svg {
      margin-right: 0.6rem;
    }
  }

  &__price {
    font-weight: 500;
    padding-top: 0.5rem;

    span {
      font-weight: 600;
    }
  }

  &__price-free {
    background: #A40000;
    font-weight: 600;
    color: white;
    padding: 0.2rem 1rem;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    display: block;
  }
}