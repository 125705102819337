.aq-watch-wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
}

.aq-watch {
  &__frame {
    width: 100%;
    height: 31rem;
    @media (max-width: 575px) {
      height: 20rem;
    }
  }

  &__text {
    padding-top: 1.5rem;
  }
  &__tags {
    padding-top: 1.5rem;
    font-family: 'Lora';
    font-size: 1.2rem;
    cursor: pointer;
  }
}