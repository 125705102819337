.competence-page {
    &__list {
        background-color: #e5e5e5;
        padding: 1rem;
        &__number {
            display: flex;
            width: 2rem;
            height: 2rem;
            color: white;
            background-color: black;
            align-items: center;
            justify-content: center;
            font-family: "Lora";
        }
    }
    &__note {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.3rem;
        margin-right: 1.4rem;
    }
    &__slider-wrap {
        margin-top: 1.5rem;
        margin-bottom: 5rem;
    }
}
