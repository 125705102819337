.gallery-items-wrap {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding-bottom: 1.5rem;
  justify-content: center;
  @media (max-width: 1199px) {
    grid-template-columns: 33% 33% 33%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 49% 49%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 98%;
    justify-items: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 242px;
    cursor: pointer;
    @media (max-width: 575px) {
      min-width: 350px;
    }
  }
}