.kit-webinar-item-inner-wrap {
  width: 100% ;
}

.kit-webinar-item-wrap {
  display: flex;
  padding: .5rem;
  border: 1px solid #dadada;
  margin-bottom: 0.7rem;

  img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
  }
}

.kit-webinar-item {
  &__text-wrap {
    padding-left: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }

  &__info {
    border-top: 1px solid #dadada;
    padding-top: 0.6rem;
    display: flex;
    justify-content: space-between;
  }
  &__price {
    font-weight: 600;
  }
}