.breadcrumbs {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.breadcrumbs-item::before {
    margin-left: 0.6rem;
    content: "/  ";
}
.breadcrumbs-item:last-of-type:after {
    display: none;
}



