.home-bg-wrapper {
  &__name {
    font-size: 4.7rem;
    line-height: 4.7rem;
    font-family: "Lora", sans-serif;
  }

  &__text {
  }

  &__text-wrapper {
    padding-top: 2rem;
    font-family: "Montserrat", sans-serif;
  }

  a, a:hover {
    color: white;
  }
}

@media (max-width: 767px) {
  .home-bg-wrapper {
    align-items: flex-end;

    &__text-mobile-cut {
      display: none;
    }

    &__name {
      font-size: 2.7rem;
    }

    &__text {
    }

    &__text-wrapper {
      padding-top: 0.4rem;
    }
  }
}
