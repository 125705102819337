.videocources-item {
  display: flex;
  flex-direction: column;
  border: 2px solid #f5f5f5;
  margin-bottom: 1.5rem;
  position: relative;

  img {
    width: 100%;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.4rem;
    @media (min-width: 576px) {
      height: 100%;
    }
  }

  &__tags {
    font-size: 0.8rem;
    color: #7d7d7d;
  }

  &__info-row {
    font-size: 0.875rem;
    color: #7d7d7d;
    display: flex;
    align-items: center;

    &_account-page {
      font-size: 0.875rem;
      color: #7d7d7d;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__clock-ico {
    margin-left: 0.6rem;
    margin-right: 0.6rem;

    &_account-page {
      margin-left: 0;
      margin-right: 0.6rem;
    }
  }

  &__price {
    color: #353535;
    font-weight: 500;
  }

  &__price > span {
    font-weight: 600;
  }

  &__price-free {
    background: #A40000;
    font-weight: 600;
    color: white;
    padding: 0.2rem 1rem;
    width: 100%;
    text-align: center;
    display: block;
    margin-top: .3rem;
  }

  &__length-wrap {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  &__discount-block {
    background-color: #82ac0b;
    width: 6.875rem;
    height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -0.4375rem;
    position: absolute;
    top: .5rem;
  }
}

.videocources__is-owner-indicator {
  background-color: #82ac0bc4;
  padding: 0.2rem 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  position: absolute;
}

.my-videocources-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #f5f5f5;
  position: relative;

  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 0.8125rem;
    position: relative;
    height: 100%;
    justify-content: space-around;
  }

  img {
    min-height: 13.8rem;
    object-fit: cover;
    width: 100%;
    @media (min-width: 576px) {
      max-height: 50%;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .kit-more-details__buy-btn {
    width: 100%;
    margin-left: 0!important;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
}
