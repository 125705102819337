.about-me-mobile {
  justify-content: center;
  &__inner-wrap {
    max-width: 1500px;
    line-height: 1.8;
    margin-bottom: 1rem;
  }
  &__text {
    line-height: 1.8rem;
    font-size: 1rem;
    text-align: center;
  }
  &__button-wrap {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1.75rem;
  }
}