.about-me {
  justify-content: center;
  margin-bottom: 3rem;

  &__text-wrap {
    padding-left: 2rem;
    p {
      margin-bottom: 1rem;
    }
  }

  &__link {
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1.75rem;
    svg {
      margin-left: 9px;
      font-size: 1.4rem;
    }
  }
}

.about-me-inner-wrap {
  max-width: 1500px;
  line-height: 1.8rem;
}