.consultation-page {
    padding-bottom: 3rem;
    &__price {
        font-family: Lora;
        font-size: 1.222rem;
        line-height: 2.556rem;
    }
    &__subtitle {
        font-weight: 600;
        font-size: 1.222rem;
        line-height: 1.5rem;
        margin-top: 2.778rem;
        margin-bottom: 2.778rem;
    }
    &__actions > div {
        font-weight: 500;
        line-height: 2.322rem;
        display: flex;
    }
    &__actions > div::before {
        font-family: "FontAwesome";
        content: "\f111";
        padding-right: 0.5rem;
    }
    &__date {
        display: flex;
        margin-top: 3.889rem;
        margin-bottom: 1.389rem;
        &__icon {
            font-size: 1.8rem;
            width: 6.4rem;
            background-color: black;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__text-wrapper {
            background: #f2f2f2;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 0.7rem;
        }
    }
    a {
        font-weight: 600;
        cursor: pointer;
        color: black !important;
    }
}
