.online-school-outer-wrap {
  padding-bottom: 3rem;
}

.online-school {
  &__title-wrap {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  &__btn {
    background-color: #cccccc;
    padding: .8rem 2rem;
    color: black;
    font-weight: 500;
    cursor: pointer;
    margin-left: 1rem;
    @media (max-width: 575px) {
      padding: .3rem .5rem;
    }
  }

  &__btn:hover {
    background-color: rgb(237, 29, 36);
    padding: .8rem 2rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 575px) {
      padding: .3rem .5rem;
    }

    a {
      text-decoration: none;
    }
  }

  &__accordion-wrap {
    .accordion-button {
      font-weight: 600;
    }

    .stepper {
      @media (max-width: 575px) {
        display: none;
      }
    }

    p {
      text-indent: 0;
      margin-bottom: 0.8rem;
    }

    .accordion-collapse {
      border-top: 1px solid #dfdfdf;
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
      --bs-table-accent-bg: white;
      color: var(--bs-table-striped-color);
    }

    .btn-primary:active, {
      background-color: #d21c22;
      outline: none;
      border: 2px solid red;
    }

    .btn-primary:focus {
      background-color: #d21c22;
      outline: none;
      border: 2px solid red;
      box-shadow: none;
    }
  }

  .btn-primary {
    box-shadow: 0px 24px 32px rgb(223 7 9 / 14%), 0px 16px 24px rgb(223 7 9 / 10%), 0px 2px 6px rgb(223 7 9 / 8%), 0px 0px 1px rgb(0 0 0 / 4%);
  }

}

