.videoreviev-slideritem {
  display: flex;
  flex-direction: column;
  height: 36rem;
  @media (max-width: 992px) {
    height: 30rem;
  }

  &__name {
    font-size: 1.1rem;
    font-family: Lora;
    padding-top: 0.4rem;
    text-align: center;
  }

  &__iframe {
    height: 100%;
    width: 100%;
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__img-wrap {
    height: 100%;
    width: 100%;
    position: relative;

    &__circle {
      z-index: 1;
      color: white;
      position: absolute;
      font-size: 4rem;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      @media (max-width: 767px) {
        font-size: 6rem;
      }
    }
  }
}