.sign-in {
    input {
        margin-bottom: 1rem;
    }
}

.signin-password-form-label {
    width: 100%;
}
.signin-password-recovery {
    float: right;
    color: #676767;
    cursor: pointer;
}
