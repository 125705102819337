.tariffs_page {

  form {
    display: none;
  }

  .prices-sl-wrap-inner {
    max-width: 1500px;
    display: flex;
    justify-content: center;
    gap: 25px;
    padding-bottom: 3rem;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .prices-sl {
    font-weight: 600;
    font-size: 2.222rem;
    text-align: center;
    color: #121212;
    padding-top: 4.444rem;
    padding-bottom: 2.5rem;

    @media (max-width: 767px) {
      font-size: 1.8rem;
      padding-top: 2.444rem;
      padding-bottom: 1.5rem;
    }

    &__item-text-wrap {}

    &__items-wrap {
      max-width: 32%;

      @media (max-width: 1300px) {
        max-width: 48%;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      background-color: #FFEBDB;
      border-radius: 15px;
      padding-left: 1.8rem;
      padding-right: 1.8rem;
      padding-bottom: 1.8rem;
      z-index: 10;
      min-height: 100%;

      &-text-wrap {
        margin-bottom: auto;
      }

      &__list {
        display: flex;
        flex-direction: column;

        span {
          padding-bottom: 1.5rem;
        }

        span::before {
          font-family: "FontAwesome";
          content: "\f111";
          padding-right: 0.5rem;
          color: #DF0709;
        }

        .documents {
          &__title {
            font-weight: bold;
            padding-bottom: 1.5rem;
          }

          &__list {
            display: flex;
            flex-direction: column;
          }

          &__bottom {
            padding-bottom: 1.5rem;
          }
        }
      }

      &__price-wrap {
        //display: flex;
        //flex-direction: column;
      }

      &__price-top {
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        span:first-child {
          color: #6A6A6A;
        }
      }

      &__price-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:first-child {
          color: #6A6A6A;
          font-weight: 500;
          font-size: 1.533rem;
        }

        span:last-child {
          font-weight: 600;
          font-size: 1.833rem;
          color: #020202;
        }
      }

      &__buttons-wrap {
        .payment-epoint-button-wrapper {
          margin: 0;

          .payment-epoint-button__buy-btn {
            width: 100%;
            font-size: 1rem;
            text-align: center;
            background: #E47571;
            border-radius: 21px;
            padding: 0.7rem;
            border: 2px solid #E47571;
            margin-top: .9rem;
            font-weight: 400;
            color: inherit;
            cursor: pointer;
          }

          .payment-epoint-button__buy-btn:hover {
            box-shadow: none;
            text-decoration: none;
          }
        }

        .terms {
          font-size: 1rem;
          padding-top: 1.5rem;

          p {
            text-indent: 1rem;
            margin-bottom: 0.5rem;
          }

          .azn {
            font-size: 0.8rem;

            .asterisk {
              display: inline;
              font-size: 12px;
              padding-right: 5px;
            }
          }
        }
      }

      &__button-1 {
        text-align: center;
        background: #E47571;
        border-radius: 21px;
        padding: 0.7rem;
        border: 2px solid #E47571;
        margin-top: .9rem;
        cursor: pointer;
      }

      &__button-2 {
        text-align: center;
        border-radius: 21px;
        padding: 0.7rem;
        border: 2px solid #E47571;
        margin-top: .9rem;
        cursor: pointer;
      }
    }
  }

  .prices-sl-mobile {
    .prices-sl {
      &__item {
        &__title {
          color: #404040;
          font-weight: 500;
          font-size: 1.244rem;
          padding-top: 1rem;
        }
      }
    }
  }
}