.qa-item {
  display: flex;
  flex-direction: column;
  position: relative;

  &__image {
    filter: brightness(50%);
  }

  &__circle {
    z-index: 1;
    color: white;
    position: absolute;
    font-size: 4rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    @media (max-width: 767px) {
      font-size: 3rem;
    }
  }
  &__tags {
    padding-top: 1rem;
    color: #282828;
  }
  &__title {
    padding-top: .5rem;
    font-family: Lora;
  }
  text-decoration: none;
}