.kits-item {
  display: flex;
  justify-content: center;
  @media (max-width: 576px) {
    max-width: 100%;
    min-width: 100%;
  }

  form {
    width: 100%;
  }

  &__wrap-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-color: #f5f5f5;
    border: 1px solid #f1f1f1;
    padding: 1.25rem;

    img {
      width: 100%;
      // height: 100%;
      align-self: center;
    }
  }

  &__discount-block {
    background-color: #82ac0b;
    width: 6.875rem;
    height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -0.4375rem;
    position: absolute;
  }
  &__text-wrap {
    display: flex;
    flex-direction: column;
  }
}

.kit-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.kit-price-wrap {
  font-weight: 500;
  padding-top: 0.65rem;
  padding-bottom: 1.25rem;
}

.kit-price {
  color: #adadad;
  font-weight: 400;
}

.kit__buy-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 15.3px;
  background-color: black;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border: none;
  width: 100%;
}
